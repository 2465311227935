import { Injectable } from '@angular/core';
import { AES, enc } from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private mapAutoVar = 'MtPdG8bpbaGu8iLJyyLORRuJ9RqhrQ2pZqYhqoO0Xw6gYP5t';

  constructor() { }

  commonMethod(val: string): string {
    const sendStr = AES.decrypt(val, this.mapAutoVar);
    return sendStr.toString(enc.Utf8);
  }

  encrypt(value: string) {
    console.log(AES.encrypt(value, this.mapAutoVar).toString());
  }
}