export const environment = {
  production: true,
  envName: 'prod',
  API_URL: 'https://api-now.engineer.ai',
  STUDIO_API_URL: 'https://api-builder.engineer.ai',
  STUDIO_URL: 'https://studio.builder.ai',
  BUILDER_PAYMENT_URL: 'https://studio.builder.ai/instant_prototype?build_card_id={buildCardId}',
  BUILDER_FEATURES_URL: 'https://studio.builder.ai/#/features',
  USER_COOKIE: 'user_prod',
  GUIDLINE_TOOTIP_COOKIE: 'prod_guidline',
  enableSentry: false,
  tracker_user: 'tracker_prod',
  tracker_user_beta: 'tracker_prod_beta',
  API_TRACKER_URL: 'https://api-tracker-uiw.builder.ai',
  API_NOW_URL: 'https://api-tracker-uiw.builder.ai',
  TRACKER_STAGING_URL: 'https://api.tracker.engineer.ai',
  API_360: 'https://api-pmdashboard.engineer.ai/',
  API_360_TOKEN:'U2FsdGVkX19p28yjzzQiPwI8D7ahmQxxqvLpMDLoDp+PmK6ENormkz/wqnJEgEZX',
  redirectionTracker: 'https://tracker.builder.ai',
  bucket_document: 'https://builder-now-production.s3.amazonaws.com',
  base_Sketch:'https://cmt.builder.ai',
  base_Sketch_bucket:'https://prod-cmt-assets.s3.amazonaws.com/',
  API_360_KEY_NOTES:'U2FsdGVkX1/s+EayZC6SDR27VHwSm7s1AUrxcdvOVUt028XCRoyocHRWx+i99oCN',
  white_board_base_url: "https://www.whiteboard.team",
  whiteBoardClientId: "U2FsdGVkX1/1ecPl/sBReebhFx5cicM+bp/ZOAc+wS0AhDX+MJvtAmF2gx/CqGhQvF2KCyFmUm45rNeKQ23L6Q==",
  whiteBoardClientSecret: "U2FsdGVkX1/5MxWX4jxqesmyXy5QggqbQRPVyKU9y87c11os6m/a+tOHvPhYp3C5yCf8huqYgyOCLnTHMxgR7w==",
  nowUrl:'https://now.builder.ai',
  HIVE_URL: 'https://developer.builder.ai/login',
  X_FIGMA_TOKEN:'U2FsdGVkX19IljTmaIqjEI6mhmryqEVFKhwTp+Lx0FI987daCJBBSBXQfNpcy3I3UX6H+iKKDPkLDmmUpr4X/w==',
  FIGMA_URL: 'https://www.figma.com',
  apeiros:'https://whiteboard.builder.ai',
  cmtFeedBackLoop :'https://cmt.builder.ai/api/v2/', 
  cmtToken : 'U2FsdGVkX18cJVfBot/05uFN326gMlbbwvH3p2bM+ESUXBtVeEju/vB1PIuX/FFqs9x4eXiNvAJmK8jXzQpjhQ==',
  nowToken : 'U2FsdGVkX18PIkm/t16oRA6j1iVXdkjv17m0lqrVVycYdrnnsQJaDlCWtWyHFDn7',
  trackerSocekt: 'https://api.tracker.engineer.ai/api/v1/sockets/auth',
  socketUrl: 'wss://centrifugo.tracker.engineer.ai/connection/websocket',
  cmtUserName:'uiw_now_production',
  API_360_BCHT:'U2FsdGVkX184/FtaP+h/jRcmAcpCuOttNriadu+l10AvHyxQL2kBetKxFML9oXxD',
  bchat_env :'bchat_production',
  authenticationToken:'U2FsdGVkX1/Eohn0kuuPmnc/4J94oe1zAKyOzAihazTUcBK4/KU8rlC57iBsTFxvT5BAz/6w6XQaamk9bVSzhQ==',
  apiEndpointBchat:'chat.builder.ai'
 };
